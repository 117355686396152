import { ContentCardData, CourseStatus } from "../cmsUtilities";

export type CourseResponseItem = {
  courseId: String;
  status: CourseStatus;
};

export type CourseAPIResponse = Array<CourseResponseItem>;

export type CourseStatusStore = Record<CourseStatus, Array<String>>;

const checkStatus = (courseId: String, store: CourseStatusStore) => {
  let result = CourseStatus.TO_DO;
  if (store[CourseStatus.COMPLETE].includes(courseId)) {
    return CourseStatus.COMPLETE;
  } else if (store[CourseStatus.IN_PROGRESS].includes(courseId)) {
    return CourseStatus.IN_PROGRESS;
  }
  return result;
};

export const getCourseStatus = (
  courseIds: Array<String>,
  store: CourseStatusStore
) => {
  if (courseIds.length > 1) {
    // Learning Path
    const allStatuses = courseIds.map(id => checkStatus(id, store));
    if (allStatuses.includes(CourseStatus.IN_PROGRESS)) {
      return CourseStatus.IN_PROGRESS;
    } else {
      const allComplete =
        allStatuses.filter(stat => stat === CourseStatus.COMPLETE).length ===
        courseIds.length;
      const someComplete = allStatuses.includes(CourseStatus.COMPLETE);
      if (allComplete) {
        return CourseStatus.COMPLETE;
      } else if (someComplete) {
        //User has finished one section of a course and not started another;
        return CourseStatus.IN_PROGRESS;
      } else {
        return CourseStatus.TO_DO;
      }
    }
  } else if (courseIds.length === 1) {
    // Normal Course
    const courseId = courseIds[0];
    return checkStatus(courseId, store);
  }
};

export const getCourseCardStatus: (
  cards: Array<ContentCardData>,
  store: CourseStatusStore
) => Array<ContentCardData> = (cards, store) => {
  return cards.map(card => {
    if (card.tiCourseIds) {
      if (card.tiCourseIds.length === 0) {
        return card;
      } else {
        //Lookup Status
        const cardData: ContentCardData = {
          ...card,
          courseStatus: getCourseStatus(card.tiCourseIds, store)
        };
        return cardData;
      }
    } else {
      return card;
    }
  });
};
