











import VideoPlayer from './VideoPlayer';

export default VideoPlayer;
