import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';

@Component({
  name: 'video-player',
  inheritAttrs: false
})
export default class VideoPlayer extends Vue {
  @Prop({ type: String })
  videoUrl!: String;
}
