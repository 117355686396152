<template>
  <div class="contentful-container">
    <contentful-rich-text
      v-if="document && document.json"
      :document="document.json"
      :nodeRenderers="renderNodes()"
      :markRenderers="renderMarks()"
    ></contentful-rich-text>
  </div>
</template>

<script lang="js">
import CmsContentfulComponent from './CmsContentfulComponent';
export default CmsContentfulComponent;
</script>
<style lang="less" scoped src="./CmsContentfulComponent.less"></style>
