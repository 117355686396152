import { Inject, Prop, } from 'vue-property-decorator';

import CmsCardComponent from '@/commoncomponents/CmsCardComponent/CmsCardComponent.vue';
import BreadcrumbComponent from '@/commoncomponents/breadcrumbComponent/BreadcrumbComponent.vue';
import MobileScreenUnavailable from '@/commoncomponents/mobileScreenUnavailable/MobileScreenUnavailable.vue';
import DeviceWidthCheckerMixin from '@/mixins/deviceWidthChecker.mixin';
import { Component, Mixins, Watch } from 'vue-property-decorator';
import CmsAccordion from '@/commoncomponents/CmsAccordion/CmsAccordion.vue';
import CmsCardCarousel from '@/commoncomponents/CmsCardCarousel/CmsCardCarousel.vue';
import CmsLabelComponent from '@/commoncomponents/CmsLabelComponent/CmsLabelComponent.vue';
import CmsTagComponent from '@/commoncomponents/CmsTagComponent/CmsTagComponent.vue';
import Checkmark from '../../assets/images/cms/checkmark.svg';
import Bullet from '../../assets/images/cms/link_bullet_blue.svg';
import ChevronRight from '../../assets/images/cms/chevron_right_nav_link.svg';
import InfoIcon from "../../assets/images/cms/information_gray.svg";
import CloseIcon from "../../assets/images/cms/close_icon.svg";
import { getContentfulGraphQLQuery, getRelatedContentfulGraphQLQuery } from '@/utilities/contentfulGraphQLHelpers';
import { fetchTiRedirectURL } from '@/services/dashboard/dashboardService';

import accountListStore from "@/store/modules/accountsList";
import programListStore from "@/store/modules/programList";


import axios from 'axios';
import {
  CoursePageData,
  CourseStatus,
  checkCMSEnabledReRoute,
  courseResponseToData,
  getCopyrightMessage,
  isAuthorizedContent
} from '@/utilities/cmsUtilities';
import BouncingPreloaderComponent from '@/commoncomponents/bouncingpreloadercomponent/BouncingPreloaderComponent.vue';
import APP_CONST from '@/constants/AppConst';
import CmsContentfulComponent from '@/commoncomponents/CmsContentfulComponent/CmsContentfulComponent.vue';
import CmsNotAuthorizedComponent from '@/commoncomponents/CmsNotAuthorizedComponent/CmsNotAuthorizedComponent.vue';
import { getCourseStatus } from '@/utilities/cms/courseStatus';
import { AnalyticsInjectionKey, AnalyticsService, analyticsEventNames } from '@/analytics';

@Component({
  components: {
    'bread-crumb': BreadcrumbComponent,
    'mobile-unavailable-screen': MobileScreenUnavailable,
    'cms-card': CmsCardComponent,
    'collapsible-layout-card': CmsAccordion,
    'cms-tag-list': CmsTagComponent,
    'cms-content-label': CmsLabelComponent,
    'card-carousel': CmsCardCarousel,
    'cms-contentful-render': CmsContentfulComponent,
    'bouncing-preloader': BouncingPreloaderComponent,
    'cms-not-authorized': CmsNotAuthorizedComponent,
  },
  props: {
    courseIdQuery: {}
  },
  data: {
    courseId: {},
    componentKey: {}
  },
})
export default class CourseLandingPage extends Mixins(DeviceWidthCheckerMixin) {
  @Inject(AnalyticsInjectionKey)
  private readonly analyticsService!: AnalyticsService;

  //Images from assets folder
  checkmarkImage = Checkmark;
  resourceLinkImage = Bullet;
  navLinksChevron = ChevronRight;
  infoIcon = InfoIcon;
  closeIcon = CloseIcon;

  tiCourseUrl: string = '';
  tiCourseButtonText: string = '';

  courseStatus: CourseStatus | undefined = undefined;
  isCompleted: boolean = true;

  courseId: string = '';

  @Prop()
  courseIdQuery: string | undefined;

  courseData: CoursePageData = {
    title: '',
    courseId: '',
    heroImage: '',
    objectivesList: [],
    courseDescription: '',
    courseTagList: [],
    labelData: [],
    contentfulDescriptionObj: undefined,
    navLinks: [],
    resourceLinks: [],
    relatedContentCards: [],
    courseUrl: '',
    curriculumLinkPath: ''
  };
  loading: boolean = true;
  showFeedback: boolean = true;
  isAuthorized: boolean = true;

  /* istanbul ignore next */
  get lxEnabled() {
    return programListStore.appSetting;
  }
  @Watch('lxEnabled', { immediate: true, deep: true })
  cmsFeatureFlag(store: any) {
    if (checkCMSEnabledReRoute(store)) {
      this.$router.push({ path: '/' });
    }
  }

  get cmsCourseStatus() {
    return accountListStore.cmsCourseStatus;
  }

  async fetchData() {
    const url = `${APP_CONST.CONTENTFUL_GRAPHQL_URL}${APP_CONST.CONTENTFUL_GRAPHQL_SPACE}${APP_CONST.CONTENTFUL_GRAPHQL_ENV}`;
    const headers = { Authorization: `Bearer ${APP_CONST.CONTENTFUL_GRAPHQL_TOKEN}` };
    const method = 'post';
    return axios({
      url: url,
      headers: headers,
      method: method,
      data: {
        query: getContentfulGraphQLQuery('course', this.courseId)
      }
    }).then((courseResult) => {
      let coursePageData = courseResult;
      // Fetch related content
      axios({
        url: url,
        headers: headers,
        method: method,
        data: {
          query: getRelatedContentfulGraphQLQuery('course', this.courseId, true)
        }
      }).then((relatedResult) => {
        // Merge related content into course data
        coursePageData.data.data.course = {
          ...coursePageData.data.data.course,
          ...relatedResult.data.data.course
        };
        const structuredData = courseResponseToData(coursePageData);
        this.isAuthorized = isAuthorizedContent(structuredData);
        this.courseData = {
          ...structuredData
        };
        this.tiCourseUrl = this.courseData.courseUrl || "";
        this.loading = false;

        accountListStore.setAccountCourseStatuses();

        return structuredData;
      }).catch(err => {
        console.error(`Failed to Fetch Related Content`, err);
      });
    }).catch(err => {
      console.error(`Failed to Fetch Landing Page`, err);
    });
  }

  async updatedId() {
    const id: string = this.$route.query.id as string || '';
    this.loading = true;
    this.courseId = id || '';
    this.fetchData();
  }

  async openCourse() {
    // Check for Course URL, and if it exists, fetch the redirect URL
    if (this.courseData.courseUrl !== undefined && this.courseData.courseUrl !== '') {
      // Initially set the course url to the course url as a fallback
      this.tiCourseUrl = this.courseData.courseUrl;
      // Strip the base url from the course url
      const tiUrl = new URL(this.courseData.courseUrl);
      const tiPath = tiUrl.pathname;
      // Then, fetch the redirect URL
      const resp = await fetchTiRedirectURL(tiPath);
      this.tiCourseUrl = resp.data;
      window.open(this.tiCourseUrl, '_blank');

      if (this.courseStatus === CourseStatus.TO_DO) {
        this.analyticsService.track(analyticsEventNames.LEARNING_COURSE_STARTED);
      }
      else if (this.courseStatus === CourseStatus.COMPLETE) {
        this.analyticsService.track(analyticsEventNames.LEARNING_COURSE_REPEATED);
      }
    }
  }

  openCurriculum() {
    // Clear exisiting filter data
    this.$store.dispatch('catalogFilterStore/resetCatalogFilterDataAction', true);
    this.$router.push({ path: APP_CONST.APP_ROUTES.CMS_CATALOG.path, query: { subscriptionCategory: this.courseData.curriculumLinkPath } });
  }

  openTag(tag: string) {
    this.$router.push({ path: APP_CONST.APP_ROUTES.CMS_CATALOG.path, query: { tag: tag } });
  }

  openFeedback() {
    window.open(this.courseData.feedbackUrl, '_blank');
  }

  getCopyrightMessage = getCopyrightMessage;

  async setCourseStatus() {
    const ids =
      this.courseData.tiCourseIds !== undefined
        ? this.courseData.tiCourseIds
        : [];
    const status = getCourseStatus(ids, this.cmsCourseStatus);
    if (status !== undefined) {
      this.courseStatus = status;
    } else {
      this.courseStatus = CourseStatus.TO_DO;
    }
    switch (this.courseStatus) {
      case CourseStatus.TO_DO:
        this.tiCourseButtonText = 'Start Course';
        break;
      case CourseStatus.IN_PROGRESS:
        this.tiCourseButtonText = 'Continue';
        break;
      case CourseStatus.COMPLETE:
        this.tiCourseButtonText = 'Retake';
        break;
      default:
        this.tiCourseButtonText = 'Start Course';
        break;
    }
  }

  beforeMount() {
    const id = this.$route.query.id;
    if (id) {
      this.courseId = id.toString();
    }
    this.$watch('$route', this.updatedId);
    this.fetchData();
    this.$watch('cmsCourseStatus', this.setCourseStatus, { deep: true });
  }

  dismissFeedback() {
    this.showFeedback = false;
  }
}
