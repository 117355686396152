import { render, staticRenderFns } from "./VideoPlayer.vue?vue&type=template&id=e82e5ba6&scoped=true&"
import script from "./VideoPlayer.vue?vue&type=script&lang=ts&"
export * from "./VideoPlayer.vue?vue&type=script&lang=ts&"
import style0 from "./VideoPlayer.less?vue&type=style&index=0&id=e82e5ba6&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e82e5ba6",
  null
  
)

export default component.exports